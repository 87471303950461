import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Reasons from "../components/reasons"
import ImageGallery from "react-image-gallery"

const Gallery = props => {
  const {
    data: { markdownRemark },
  } = props
  const images = markdownRemark.frontmatter.slider.slide.map(image => ({
    original: image.image,
    thumbnail: image.image,
  }))
  return (
    <Layout>
      <SEO title="Gallery" />
      <main className="main-container">
        <div className="title-container">
          <h1 className="page-title">{markdownRemark.frontmatter.title}</h1>
        </div>
        <div
          style={{ background: "none", maxWidth: 800, margin: "0 auto" }}
          className="main-content-container"
        >
          <ImageGallery items={images} />
        </div>
        <Reasons bottom />
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query GalleryPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Gallery" } }) {
      frontmatter {
        title
        slider {
          slide {
            image
          }
        }
      }
    }
  }
`

export default Gallery
